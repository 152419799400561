<template>
  <!-- 承接推送信息  （货源找车 未找车详情） -->
  <div class="accept-push-info-wrap">
    <div class="card-title">承接信息</div>
    <div class="flex">
      <div class="info-item">
        <div class="label">承接价格：</div>
        <div class="value"><span>{{ originInfo.acceptPrice||'-' }} {{ unitMap[originInfo.unit] }}</span></div>
      </div>
      <div class="info-item">
        <div class="label">承接车数：</div>
        <div class="value">
          <span>{{ originInfo.acceptCarNumber }} 车</span>
        </div>
      </div>
    </div>
    <div class="card-title pt10">推送设置</div>
    <div class="flex">
      <div class="info-item">
        <div class="label">优先级：</div>
        <div class="value">{{ $CONSTANT.priorityMap[+pushSetBaseInfo.priority] }}</div>
      </div>
      <div class="info-item">
        <div class="label">是否推送至平台优选：</div>
        <div class="value">{{ +pushSetBaseInfo.isPushPlatForm===1?'是':'否' }}</div>
      </div>
    </div>
    <div class="flex">
      <div class="info-item2">
        <div class="label">找车指导价：</div>
        <div class="value">
          <span class="mr10">{{ $CONSTANT.priceTypeMap[+pushSetBaseInfo.priceType] }}</span><span>{{ pushSetBaseInfo.price||'-' }} {{ unitMap[+pushSetBaseInfo.priceUnit] }}</span>
          <span :class="['ml10',$CONSTANT.taxColorMap[+pushSetBaseInfo.needTax]]">{{ $CONSTANT.taxMap[+pushSetBaseInfo.needTax]||'-' }}</span>
        </div>
      </div>
    </div>
    <el-form ref="form" :model="form" :rules="rules" label-position="right" label-width="150px">
      <el-form-item label="推送承运人：" prop="user">
        <el-col v-if="showActions" :span="24">
          <span class="main-c cursor" @click="add">新增推送承运人</span>
          <el-form ref="formTable" class="form-wrap" :model="form" :rules="tableRules" :inline="true">
            <div class="push-table">
              <el-table
                v-if="form.user.length"
                class="select-table-wrap"
                :data="form.user"
                style="width: 100%"
                border
                max-height="505px"
              >
                <el-table-column prop="companyName" label="承运商用户" align="center">
                  <template slot-scope="{ row }">
                    <UserInfoCard size-type="table" :need-change-user-info="false" :need-match="false" :user-info="row" :user-type="2" />
                  </template>
                </el-table-column>
                <el-table-column
                  prop="titleTypeDesc"
                  label="业务资质类型"
                  align="center"
                />
                <el-table-column
                  prop="phone"
                  label="联系电话"
                  align="center"
                />
                <el-table-column label="运价类型" prop="priceType" align="center">
                  <template slot-scope="{ row, $index }">
                    <el-form-item :prop="'user.' + $index + '.priceType'" :rules="tableRules.priceType" size="small">
                      <el-select
                        v-model="row.priceType"
                        placeholder="请选择"
                        @change="
                          (row) => {
                            tableChangeTariffType(row,$index);
                          }
                        "
                      >
                        <el-option key="1" label="运价" :value="1" />
                        <el-option key="2" label="信息费" :value="2" />
                      </el-select>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="是否含税" prop="needTax" align="center">
                  <template slot-scope="{ row,$index }">
                    <el-form-item :prop="'user.' + $index + '.needTax'" :rules="tableRules.needTax" size="small">
                      <el-select
                        v-model="row.needTax"
                        placeholder="请选择"
                        :disabled="row.disabled"
                      >
                        <el-option v-for="item in $CONSTANT.taxArrayMap" :key="item.value" :label="item.label" :value="item.value" />
                      </el-select>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="价格" prop="price" align="center">
                  <template slot-scope="{ row, $index }">
                    <div class="flex-c-c">
                      <el-form-item :prop="'user.' + $index + '.price'" :rules="tableRules.price" size="small">
                        <el-input v-model="row.price" :disabled="false" @input="validateNumber2('price',9,2,$index)" />
                      </el-form-item>
                      <span v-if="+row.priceType===1">{{ unitMap[+pushSetBaseInfo.priceUnit] }}</span>
                      <span v-else>元/车</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  label="操作"
                  align="center"
                  width="80px"
                >
                  <template slot-scope="{ row }">
                    <div class="main-c cursor" @click="deleteSelectItem(row)">移除</div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-form>
        </el-col>
      </el-form-item>
    </el-form>
    <el-button v-if="showActions" type="primary" class="submit-btn" @click="submit">推送</el-button>
    <div v-if="showRecords" class="mt20">
      <div v-for="(item, index) in recordList" :key="index" class="push-list">
        <div class="time">{{ item.createTime|parseTime }}</div>
        <div class="push-table">
          <el-table
            class="select-table-wrap"
            :data="item.goodsRecordVOList"
            style="width: calc(100% - 80px)"
            border
            max-height="505px"
          ><el-table-column
             prop="companyName"
             label="承运商用户"
             align="center"
           >
             <template slot-scope="{ row }">
               <UserInfoCard size-type="table" :need-change-user-info="false" :need-match="false" :user-info="row.customerUserVO" :user-type="2" />
             </template>
           </el-table-column>
            <el-table-column
              prop="titleType"
              label="业务资质类型"
              align="center"
            >
              <template slot-scope="{ row }">
                <div>{{ $CONSTANT.qualificationTypeMap[+row.customerUserVO.titleType]||'-' }}</div>
              </template>
            </el-table-column>
            <el-table-column
              prop="phone"
              label="联系电话"
              align="center"
            >
              <template slot-scope="{ row }">
                <div>{{ +row.customerUserVO.userPhone }}</div>
              </template>
            </el-table-column>
            <el-table-column label="运价类型" prop="priceType" align="center">
              <template slot-scope="{ row }">
                <div>{{ priceTypeMap[row.priceType]||'-' }}</div>
              </template>
            </el-table-column>
            <el-table-column label="是否含税" prop="needTax" align="center">
              <template slot-scope="{ row }">
                <div>{{ $CONSTANT.taxMap[+row.needTax]||'-' }}</div>
              </template>
            </el-table-column>
            <el-table-column label="价格" prop="price" align="center">
              <template slot-scope="{ row }">
                <div class="flex-c-c">
                  <span>{{ $parseNumber(row.price) }}</span>
                  <span v-if="+row.priceType===1">{{ unitMap[+pushSetBaseInfo.priceUnit] }}</span>
                  <span v-else>元/车</span>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div v-if="!recordList.length" class="push-list">暂无数据</div>
    </div>
    <DialogTable :dialog-visible.sync="showDialog" width="1089px" dialog-title="选择承运人" :select-list="form.user" type="car" :disable-i-d-list="disableIDList" @dialogSubmit="dialogSubmit" />
  </div>
</template>

<script>
import supplyTask from '@/api/goodsOrder/supplyTask';
import supplyLookCar from '@/api/goodsOrder/supplyLookCar';
import DialogTable from '@/views/goodsOrder/components/detail/dialogTable';
import { parseTime } from '@/utils';
export default {
  components: { DialogTable },
  filters: { parseTime },
  props: {
    orderId: {
      type: [Number, String],
      default() {
        return null;
      },
    },
    recordId: {
      type: [Number, String],
      default() {
        return null;
      },
    },
    showRecords: {
      type: Boolean,
      default() {
        return false;
      },
    },
    showActions: {
      type: Boolean,
      default() {
        return true;
      },
    },
  },

  data() {
    return {
      originInfo: {}, // 承接信息数据
      pushSetBaseInfo: {}, // 推送设置基础数据
      unitMap: this.$CONSTANT.transportPriceUnitMap.O,
      priceTypeMap: {
        1: '运价',
        2: '信息费',
      },
      form: {
        user: [],
      },
      rules: {
        user: [
          { required: true, message: '请选择推送车队', trigger: 'blur' },
        ],
      },
      tableRules: {
        priceType: [{ required: true, message: '请选择运价类型' }],
        needTax: [{ required: true, message: '请选择是否含税' }],
        price: [{ required: true, message: '请输入合理价格' }],
      },
      showDialog: false,
      // isWhite: 0, // 是否白名单 白名单 显示卸结预估价 反之不显示
      // unloadingEstimateRes: null, // 卸结预估价
      recordList: [],
      disableIDList: [],
    };
  },
  created() {
    this.getOrderGoodsReportDetail();
    this.getBasePushSet();
    this.getList();
  },
  methods: {
    // 获取报价详情（获取承接价格和承接车数）
    getOrderGoodsReportDetail() {
      const params = {
        gId: this.orderId,
        type: 2, // type 1前线 2小五 3车队
      };
      supplyTask.orderGoodsReportDetail(params).then(res => {
        if (res) {
          this.originInfo = {
            id: res.id,
            acceptPrice: this.$parseNumber(res.price) || '',
            acceptCarNumber: res.truckNum || 0,
            unit: res.priceUnit || 1,
            needTax: +res.needTax || 0,
          };
        }
      });
    },
    // 推送设置详情
    getBasePushSet() {
      supplyLookCar.orderGoodsXwPushDetail(this.orderId).then(res => {
        this.pushSetBaseInfo = {
          priority: res.priority || 3,
          isPushPlatForm: res.isPushPlatForm || 0,
          priceType: res.priceType || 1,
          price: this.$parseNumber(res.price) || 0,
          priceUnit: res.priceUnit || 1,
          needTax: res.needTax || 1,
        };
      });
    },
    getList() {
      supplyLookCar.orderGoodsRecordTransList({ id: this.orderId, var1: this.recordId }).then(res => {
        this.recordList = res || [];
        if (res && +this.$route.query.status === 0) {
          // 跳转到已找车中详情
          this.$router.replace({
            path: '/goodsOrder/supplyLookCar/detail',
            query: {
              id: this.orderId,
              status: 1,
              recordId: this.recordId,
            },
          });
          this.$emit('toSendDetail');
        }
      });
    },
    // 获取已推送车队id数组 便于置灰回显
    getHasPushTransList() {
      supplyLookCar.orderGoodsRecordTransUserList({ id: this.orderId }).then(res => {
        this.disableIDList = res || [];
      }).catch(() => {
        // 接口失效 用列表数据作为置灰回显（仅能置灰此唯一recordId单的已推送车队userId 不能代表全部）
        this.disableIDList = this.recordList.flatMap(obj => obj.goodsRecordVOList.map(item => item.userId)) || [];
      }).finally(() => {
        this.showDialog = true;
      });
    },
    add() {
      if (+this.$route.query.status !== 0) {
        this.getHasPushTransList();
      } else {
        this.showDialog = true;
      }
    },
    tableChangeTariffType(data, index) {
      if (+data === 2) {
        this.form.user[index].needTax = 0;
        this.form.user[index].disabled = true;
      } else {
        // this.form.user[index].needTax = 1;
        this.form.user[index].disabled = false;
      }
    },
    deleteSelectItem(data) {
      this.form.user.forEach((item, i) => {
        if (data.id === item.id) {
          this.form.user.splice(i, 1);
        }
      });
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$refs.formTable.validate((validTable) => {
            if (validTable) {
              const newMapUser = this.form.user.map((d) => {
                if (+this.originInfo.acceptCarNumber === 1) {
                  return {
                    gId: this.orderId, // 货单Id
                    type: 3, // 2推送小五 3推送车队
                    price: this.$parseNumber(d.price, 100, 'times'), // 费用
                    priceType: d.priceType, // 费用类型 1运价  2信息费
                    needTax: d.needTax, // 是否含税 1含税 0不含税
                    // enable: 0, // 0货单未承接 1货单已承接
                    userId: d.id,
                    truckNum: 1,
                  };
                } else {
                  return {
                    gId: this.orderId, // 货单Id
                    type: 3, // 2推送小五 3推送车队
                    price: this.$parseNumber(d.price, 100, 'times'), // 费用
                    priceType: d.priceType, // 费用类型 1运价  2信息费
                    needTax: d.needTax, // 是否含税 1含税 0不含税
                    // enable: 0, // 0货单未承接 1货单已承接
                    userId: d.id,
                  };
                }
              });
              supplyLookCar.orderGoodsPushTrans(newMapUser).then(res => {
                if (res) {
                  this.$baseMessage('提交成功!', 'success');
                  this.form.user = [];
                  // this.$refs.form.resetFields();
                  // 如果status0 此时实在未推送详情 需要跳转到已找车中详情
                  if (+this.$route.query.status === 0) {
                    this.$router.replace({
                      path: '/goodsOrder/supplyLookCar/detail',
                      query: {
                        id: this.orderId,
                        status: 1,
                        recordId: this.recordId,
                      },
                    });
                    this.$emit('toSendDetail');
                  } else {
                    // 此时是已推送详情 需要刷新推送记录
                    this.getList();
                    this.$emit('actionSuccess');
                  }
                }
              });
            }
          });
        }
      });
    },
    dialogSubmit(data) {
      data.forEach(item => {
        item.priceType = 1;
        item.needTax = +this.pushSetBaseInfo.needTax;
        // item.price = this.pushSetBaseInfo.price;
        item.price = '';
        item.disabled = false;
        item.userId = item.id;
        // item.unloadingEstimate = this.unloadingEstimateRes;
      });
      this.form.user = JSON.parse(JSON.stringify(data));
      this.$refs.form.validateField(['user']);
    },
    validateNumber(val, limitNum, decimals) {
      // 正则限制输入的金额不能为中文、特殊字符、两位小数
      const reg = /^[0-9]+.?[0-9]*/;
      // 如果不符合正则，提示
      if (!reg.test(+this.form[val])) {
        this.form[val] = '';
      }
      // 保留两位小数
      const price = this.form[val].toString();
      const pArr = price.split('.');
      pArr[0] = pArr[0].substr(0, limitNum);
      if (pArr.length > 1 && decimals) {
        this.form[val] = pArr[0] + '.' + pArr[1].substr(0, decimals);
      } else {
        this.form[val] = pArr[0];
      }
    },
    validateNumber2(val, limitNum, decimals, index) {
      // 正则限制输入的金额不能为中文、特殊字符、两位小数
      const reg = /^[0-9]+.?[0-9]*/;
      // 如果不符合正则，提示
      if (!reg.test(+this.form.user[index][val])) {
        this.form.user[index][val] = '';
      }
      // 保留两位小数
      const price = this.form.user[index][val].toString();
      const pArr = price.split('.');
      pArr[0] = pArr[0].substr(0, limitNum);
      if (pArr.length > 1 && decimals) {
        this.form.user[index][val] = pArr[0] + '.' + pArr[1].substr(0, decimals);
      } else {
        this.form.user[index][val] = pArr[0];
      }
    },
  },
};

</script>
<style lang='scss' scoped>
.accept-push-info-wrap{
  .label{
    text-align: right;
    width:140px;
    color: #A4A4A4;
  }
  .value{
    flex: 1;
  }
  .info-item{
    width:350px;
    display: flex;
    line-height: 40px;
    .original-truckNum{
      text-decoration: line-through;
    }
  }
  .info-item2{
    width:500px;
    display: flex;
    line-height: 40px;
  }
  .push-list{
    margin-bottom: 10px;
    margin-left: 140px;
    .time{
      margin-bottom: 10px;
    }
  }
  .submit-btn{
    margin-left: 150px;
  }
}
.push-table{
  ::v-deep{
    .el-table td{
      padding: 0!important;
      height: 60px;
    }
    .el-form-item__content{
      height: 60px;
      line-height: 60px;
    }
    .el-form-item {
      margin-bottom: 0;
      .el-form-item__label {
        color: #58595c;
        padding-right: 0;
      }
    }
    .el-form-item__error {
      // padding-top: 0;
      margin-top: -15px;
    }
  }
}
</style>
